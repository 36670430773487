
<template>
  <div>
    <el-form label-position="top" label-width="100px" :model="form" :rules="formRules" ref="inviteForm">
      <div class="py-3 px-5 pb-6">
        <el-form-item label="Email Id" prop="emailId">
          <el-input ref="inputEmailEle" placeholder="Enter email for receiving invitation" v-model="form.emailId"></el-input>
        </el-form-item>
        <el-form-item label="Mobile Number" style="width:265px" prop="mobileNum">
          <el-input ref="inputMobileNumber" placeholder="Enter mobile for receiving invitation" v-model="form.mobileNum"></el-input>
        </el-form-item>
        <div class="mt-3">
          <el-form-item label="Marketing Role">
            <el-radio-group v-model="form.role" size="medium" class="capitalize">
              <el-radio border label="none">No Access</el-radio>
              <el-radio border label="collaborator"></el-radio>
              <el-radio border label="admin"></el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="mt-3">
          <el-form-item label="Support Role">
            <el-radio-group v-model="form.supportRole" size="medium" class="capitalize">
              <el-radio border label="none">No Access</el-radio>
              <el-radio border label="agent"></el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <div class="bg-gray-100 border-t p-3">
        <el-form-item class="justify-end flex items-center" size="large" style="margin-bottom: 0!important;">
          <el-button size="medium" @click="$emit('close:modal')">Cancel</el-button>
          <el-button size="medium" type="primary" @click="onSubmit" :loading="isSubmitting">Invite User</el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import { ref, reactive, getCurrentInstance } from 'vue'
import { inviteNewUser } from '@/services/userService'
export default {
  emits: ['close:modal', 'refresh:user-list'],
  setup(props, { emit }) {
    const app = getCurrentInstance()
    const inviteForm = ref(null)
    const isSubmitting = ref(false)
    const inputEmailEle = ref(null)
    const inputMobileNumber = ref(null)

    const form = reactive({
      emailId: '',
      mobileNum: '',
      role: 'collaborator',
      supportRole: 'none'
    })
    const formRules = {
      emailId: [
        {
          type: 'email',
          message: 'Please input correct email address',
          trigger: []
        },
        {
          required: true,
          message: 'Please input email address',
          trigger: 'change'
        }
      ],
      mobileNum: [
        {
          required: true,
          message: 'Please input mobile number',
          trigger: 'change'
        },
        {
          pattern: /^\+?(91|44|1|61)\s?[789]\d{9}$|^\+?1\s?[2-9]\d{9}$|^07\d{9}$/,
          message: 'Please input a valid mobile number',
          trigger: 'blur'
        }
      ]
    }
    function onSubmit() {
      const prototypes = app.appContext.config.globalProperties
      inviteForm.value.validate().then(() => {
        isSubmitting.value = true
        inviteNewUser({
          email: form.emailId,
          mobile: form.mobileNum,
          role: form.role === 'collaborator' ? 'user' : form.role,
          supportRole: form.supportRole
        })
          .then(() => {
            prototypes.$message({
              message: 'Invitation Sent!',
              type: 'success'
            })
            emit('close:modal')
            emit('refresh:user-list')
          })
          .catch(err => {
            console.log(err)
            prototypes.$alert('Something went wrong when inviting new user. Please try again later!', 'Error', {
              confirmButtonText: 'OK'
            })
          })
          .finally(() => {
            isSubmitting.value = false
          })
      })
    }
    function focusInput() {
      if (inputEmailEle.value) inputEmailEle.value.focus()
    }
    function focusMobileInput() {
      if (inputMobileNumber.value) inputMobileNumber.value.focus()
    }
    return {
      inputEmailEle,
      inputMobileNumber,
      inviteForm,
      form,
      formRules,
      onSubmit,
      isSubmitting,
      focusInput,
      focusMobileInput
    }
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0;
}
</style>
